<template>
  <a-drawer :title="title" :maskClosable="true" width=650 placement="right"
    :closable="true" @close="close" :visible="visible"
    style="height: calc(100% - 55px);overflow: auto;padding-bottom: 53px;">
    <a-checkbox-group v-model="role" @change="onChange">
    <a-row v-for="item in treeData" :key="item.id">
      <a-col>
        <a-checkbox :value="item.id">
          {{item.roleName}}
        </a-checkbox>
      </a-col>
    </a-row>
    </a-checkbox-group>
    <div class="drawer-bootom-button">
      <a-popconfirm title="确定放弃编辑？" @confirm="close" okText="确定"
        cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm>
      <a-button @click="handleSubmit" type="primary" :loading="loading">提交
      </a-button>
    </div>
  </a-drawer>

</template>
<script>
import api from './user'

export default {
  name: 'RoleModal',
  data () {
    return {
      roleId: '',
      role: '',
      treeData: [],
      defaultCheckedKeys: [],
      checkedKeys: [],
      expandedKeysss: [],
      allTreeKeys: [],
      autoExpandParent: true,
      checkStrictly: true,
      title: '角色权限配置',
      visible: false,
      loading: false
    }
  },
  methods: {
    onChange (e) {
      this.checkedKeys = e
    },
    show (role) {
      this.role = role.roleId.split(',')
      this.roleId = role.id
      this.visible = true
    },
    close () {
      this.reset()
      this.$emit('close')
      this.visible = false
    },
    onExpand (expandedKeys) {
      this.expandedKeysss = expandedKeys
      this.autoExpandParent = false
    },
    reset () {
      this.expandedKeysss = []
      this.checkedKeys = []
      this.defaultCheckedKeys = []
      this.loading = false
    },
    expandAll () {
      this.expandedKeysss = this.allTreeKeys
    },
    closeAll () {
      this.expandedKeysss = []
    },
    checkALL () {
      this.checkedKeys = this.allTreeKeys
    },
    cancelCheckALL () {
      this.checkedKeys = []
    },
    handleCancel () {
      this.close()
    },
    handleSubmit () {
      const that = this
      const params = {
        userId: that.roleId,
        roleList: that.checkedKeys
      }
      that.loading = true
      api.checkrples(params).then((res) => {
        that.$message.success(res.message)
        that.loading = false
        that.close()
      })
    }
  },
  watch: {
    visible () {
      if (this.visible) {
        api.roleList().then((res) => {
          this.treeData = res.data.records
        })
      }
    }
  }
}

</script>
<style lang="less" scoped>
.drawer-bootom-button {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 2px 2px;
}
</style>
