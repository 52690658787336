<template>
  <div class="_page-content">
    <a-row :gutter="20">
      <a-col
        :md="5"
        :sm="24"
      >
        <a-alert
          type="info"
          :showIcon="true"
        >
          <div slot="message">
            当前选择：
            <span
              v-if="currSelected.title"
              style="color:black"
            >{{ getCurrSelectedTitle() }}</span>
            <a
              v-if="currSelected.title"
              @click="onClearSelected"
              style="margin-left: 10px;color:#3853db"
            >取消选择</a>
          </div>
        </a-alert>
        <a-input-search
          @search="onSearch"
          style="width:100%;margin-top: 10px"
          placeholder="请输入部门名称"
        />
        <!-- 树-->
        <a-dropdown
          :trigger="[this.dropTrigger]"
          @visibleChange="dropStatus"
        >
          <a-tree
            style="user-select: none;height: 50vh;overflow-y: auto;"
            multiple
            @select="onSelect"
            @check="onCheck"
            :selectedKeys="selectedKeys"
            :checkedKeys="checkedKeys"
            :treeData="departTree"
            :load-data="onLoadData"
            :checkStrictly="true"
            :expandedKeys="iExpandedKeys"
            :autoExpandParent="autoExpandParent"
            @expand="onExpand"
          />
        </a-dropdown>
      </a-col>
      <a-col
        :md="19"
        :sm="24"
      >
        <ax-table
          ref="tableBox"
          :show-search="true"
          :searchForm="searchForm"
          :searchActions='searchActions'
          :columns="columns"
          :dataSourceApi="api.listCheck"
          :autoLoad="false"
          :sqlParams="sqlParams"
          :dataSourceParams="dataSourceParams"
          :clickable="false"
          :scroll="{ y: '55vh', x: '80vw' }"
          @action-column-click="actionColumnClick"
        >
        </ax-table>
        <a-modal
          v-model="visible"
          destroyOnClose
          title="人员迁移"
          width="600px"
          height="400px"
        >
          <template slot="footer">
            <a-button
              type="primary"
              @click="define"
            >确定</a-button>
            <a-button
              type="primary"
              @click="handleCancel"
            >关闭</a-button>
          </template>
        </a-modal>
      </a-col>
    </a-row>
    <!-- table区域-end -->
    <DeptTree
      @close="close"
      ref="deptTree"
    ></DeptTree>

    <user-modal
      ref="modalForm"
      @ok="modalFormOk"
    ></user-modal>
  </div>
</template>

<script>
import UserModal from './UserModal'
import api from './user'
import DeptTree from './DeptTree'

export default {
  name: 'TestAxTable',
  components: {
    UserModal,
    DeptTree
  },
  data () {
    return {
      api,
      tableData: [],
      pager: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      dataSourceParams: {
      },
      // 树形部分
      departTree: [],
      departTreeCopy: [],
      treeData: [],
      currSelected: {},
      crChargeDept: '', // 分页查询部门ID
      checkedKeys: [],
      selectedKeys: [],
      autoExpandParent: true,
      iExpandedKeys: [],
      dropTrigger: '',
      // 人员迁移
      transferData: [],
      visible: false,
      queryParams: {},
      initialValues: {},
      columns: this.$ax.tools.initColumn([
        {
          title: '用户名',
          dataIndex: 'username',
          ellipsis: true,
          width: 120
        },
        {
          title: '姓名',
          dataIndex: 'realname',
          ellipsis: true,
          width: 100
        },
        {
          title: '用户角色',
          dataIndex: 'roleName',
          ellipsis: true,
          width: 120
        },
        {
          title: '联系电话',
          dataIndex: 'phone',
          ellipsis: true,
          width: 120
        }
      ], true, {
        width: 120,
        actions: [
          { props: record => { return { text: '角色设置', name: 'handleEdit', type: '#3853db', link: true, loading: record.id === 0 } } }
          // { props: record => { return { text: '删除', name: 'batchDel', type: '#3853db', link: true, loading: record.id === 0 } } },
          // { props: record => { return { text: '重置密码', name: 'handleChangePassword', type: '#3853db', link: true, loading: record.id === 0 } } }
        ]
      }),
      searchActions: [
        { name: 'search', text: '查询' },
        { name: 'reset', text: '重置' }
        // { name: 'add', text: '添加用户' }
      ],
      sqlParams: {
        like: ['realname', 'username', 'roleId']
      },
      searchForm: this.$common.initGridFormData(
        [
          { label: '用户名', type: '', model: 'username', options: [], col: { span: 8 } },
          { label: '姓名', type: '', model: 'realname', options: [], col: { span: 8 } },
          { label: '所属角色', type: 'select', model: 'roleId', options: [], col: { span: 8 } }
        ])
    }
  },
  async created () {
    this.loadTree()
    const un = await api.roleList()
    const options = []
    un.data.records.map(item => {
      const data = {
        label: item.roleName,
        value: item.id
      }
      options.push(data)
    })
    this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp('roleId', { options: { options } })
    this.getList()
  },
  methods: {
    // 树形部分代码  当前选择显示
    getCurrSelectedTitle () {
      return !this.currSelected.title ? '' : this.currSelected.title
    },
    onClearSelected () {
      this.checkedKeys = []
      this.currSelected = {}
      this.selectedKeys = []
      this.dataSourceParams.deptId = ''
      this.getList()
    },
    getList () {
      this.dataSourceParams.pageNo = this.pager.pageNo
      this.dataSourceParams.pageSize = this.pager.pageSize
      this.$refs.tableBox.getDataSource()
    },
    actionColumnClick (args) {
      const { btn: { name }, record } = args
      switch (name) {
        case 'handleEdit':
          this.$refs.modalForm.edit(record)
          this.$refs.modalForm.title = '编辑'
          this.$refs.modalForm.disableSubmit = false
          break
        case 'batchDel':
          this.batchDel(record)
          break
        case 'handleChangePassword':
          this.handleChangePassword(record)
          break
        case 'pzRoles':
          this.pzRoles(record)
          break
      }
    },
    onSearch (value) {
      const that = this
      if (value) {
        api.searchByKeywords({ keyWord: value }).then((res) => {
          that.departTree = []
          for (let i = 0; i < res.data.length; i++) {
            const temp = res.data[i]
            that.departTree.push(temp)
          }
        })
      } else {
        this.iExpandedKeys = []
        this.loading = true
        this.departTree = this.departTreeCopy
        this.loading = false
      }
    },
    loadTree () {
      this.$nextTick(() => {
        api.queryDepartTreeList().then((res) => {
          if (res.data.length > 0) {
            this.departTree = res.data
          } else {
            this.$message.warning('您没有该权限！')
          }
          this.loading = false
          this.departTreeCopy = this.departTree
        })
      })
    },
    onLoadData (treeNode) {
      return new Promise(resolve => {
        if (treeNode.dataRef.children) {
          api.queryDepartTreeList({ parentId: treeNode.dataRef.id }).then((res) => {
            if (res.data.length > 0) {
              treeNode.dataRef.children = res.data
            } else {
              // this.$message.warning('该部门下没有子部门！')
              treeNode.dataRef.leaf = true
            }
            this.loading = false
          })
          resolve()
          return
        }
        setTimeout(() => {
          this.departTree = [...this.departTree]
          resolve()
        }, 1000)
      })
    },
    onSelect (selectedKeys, e) {
      const record = e.node.dataRef
      this.currSelected = Object.assign({}, record)
      this.selectedKeys = [record.key]
      this.dataSourceParams.deptId = record.id
      this.$refs.tableBox.pagination.current = 1
      this.$refs.tableBox.getDataSource()
      this.setValuesToForm(record)
    },
    // 触发onSelect事件时,为部门树右侧的form表单赋值
    setValuesToForm (record) {
      this.crChargeDept = record.id
      this.pager.pageNo = record.total
    },
    onCheck (checkedKeys, info) {
      this.checkedKeys = checkedKeys.checked
    },
    onExpand (expandedKeys) {
      this.iExpandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    setThisExpandedKeys (node) {
      if (node.children && node.children.length > 0) {
        this.iExpandedKeys.push(node.key)
      }
    },
    // 右键点击下拉框改变事件
    dropStatus (visible) {
      if (visible === false) {
        this.dropTrigger = ''
      }
    },
    // 添加用户
    // handleAdd: function () {
    //   const key = this.currSelected.key
    //   if (!key) {
    //     this.$message.warning('请选择一个部门单位!')
    //     return false
    //   } else {
    //     this.$refs.modalForm.add(this.currSelected)
    //     this.$refs.modalForm.title = '新增'
    //     this.$refs.modalForm.disableSubmit = false
    //   }
    // },
    modalFormOk () {
      // 新增/修改 成功时，重载列表
      this.$refs.tableBox.getDataSource()
    },
    handleChangePassword (record) {
      const that = this
      this.$confirm({
        title: '确认重置',
        content: '是否重置选中数据?',
        cancelText: '取消',
        okText: '确定',
        onOk: async function () {
          const res = await api.resetPassword({ id: record.id })
          if (res.status === 200) {
            that.$message.success('操作成功')
          } else {
            that.$message.error('操作失败')
          }
        }
      })
      /* this.$refs.passwordmodal.show(record) */
    },
    handleEdit: function ({ record }) {
      this.$refs.modalForm.edit(record)
      this.$refs.modalForm.title = '编辑'
      this.$refs.modalForm.disableSubmit = false
    },
    // 单项删除
    batchDel: function (record) {
      const that = this
      this.$confirm({
        title: '确认删除',
        content: '是否删除选中数据?',
        cancelText: '取消',
        okText: '确定',
        onOk: function () {
          that.api.del({ id: record.id }).then((res) => {
            that.$refs.tableBox.getDataSource()
            that.$message.success('删除成功')
          })
        }
      })
    },
    /* 获取人员迁移选中的参数 */
    saveCs (arr, add) {
      this.transferData = add
    },
    transfer () {
      if (this.transferData.length < 1) {
        this.$message.warning('请先选择要迁移的人员!')
        return
      }
      this.visible = true
    },
    // 人员迁移关闭
    handleCancel (e) {
      this.visible = false
    },
    define () {
      if (this.$refs.deptTree.courseData.id === null) {
        this.$message.warning('请选择要转移的部门!')
        return
      }
      const that = this
      this.$confirm({
        title: '确认迁移',
        content: '此操作请慎重,是否确认迁移选中数据?',
        cancelText: '取消',
        okText: '确定',
        onOk: function () {
          that.api.move({ deptId: that.$refs.deptTree.courseData.id, ids: that.transferData }).then(res => {
            if (res.status === 200) {
              that.$message.success('转移成功')
              that.modalFormOk()
              that.handleCancel()
            } else {
              that.$message.error('操作失败')
            }
          })
        }
      })
    },
    restData () {
      this.$refs.formBox.setFieldsValue({ userName: '', userCode: '', roleCode: '' })
      api.listCheck({ crChargeDept: this.crChargeDept }).then(res => {
        this.tableData = res.data.records
        this.pager.pageNo = res.data.current
        this.pager.pageSize = res.data.size
        this.pager.total = res.data.total
      })
      this.$refs.tableBox.getTableData()
    },
    close () {
      this.$refs.tableBox.getDataSource()
    },
    // 配置角色
    pzRoles (record) {
      this.$refs.deptTree.show(record)
    },
    onValueChange (value, params) {
      switch (params) {
        case 'username':
          this.dataSourceParams.username = value
          break
        case 'realname':
          this.dataSourceParams.realname = value
          break
        case 'roleId':
          this.dataSourceParams.roleId = value
          break
      }
    }
  }
}
</script>
